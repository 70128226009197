import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import ProjectCard02 from '../../Components/ProjectElements/ProjectCard02';
import Banner from '../Banner';

const ProjectsContainer02 = () => {
    const ProjectData02 = [
        {
            id: '1',
            img: 'images/projects/RealEstate_CrownHeightsBrooklyn_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-1-CrownHeights'
        },
        {
            id: '2',
            img: 'images/projects/RS2_EastFlatbush_sold.png',
            tag: 'realestate',
            title: 'East Flatbush Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-2-EastFlatbush'
        },
        {
            id: '3',
            img: 'images/projects/frontCrownHeights-186_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-3-CrownHeights'
        },
        {
            id: '4',
            img: 'images/projects/RS4_Front_CH_card_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-4-CrownHeights'
        },
        {
            id: '5',
            img: 'images/projects/RS12_lobby_card_sold.png',
            tag: 'realestate',
            title: 'Lincoln Square NY',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-5-LincolnSquare'
        },
        {
            id: '6',
            img: 'images/projects/RealEstate_Bedfordnew_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-6-BedfordStuyvesant'
        },
        {
            id: '7',
            img: 'images/projects/RealEstate_Bedford2new_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-7-BedfordStuyvesant'
        },
        {
            id: '8',
            img: 'images/projects/RS6_front_card_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-8-BedfordStuyvesant'
        },
        {
            id: '9',
            img: 'images/projects/RS10_bayridgefront_card_sold.png',
            tag: 'realestate',
            title: 'Bay Ridge Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-9-MidtwonEast'
        },
        {
            id: '10',
            img: 'images/projects/RS11_front_card_sold.png',
            tag: 'realestate',
            title: 'Midtown East NY',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-10-LincolnSquare'
        },
        {
            id: '11',
            img: 'images/projects/RS14_front_card_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-11-CrownHeights'
        },
        {
            id: '12',
            img: 'images/projects/RS15_building_card_sold.png',
            tag: 'realestate',
            title: 'Midtown East NY',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-12-MidtownEast'
        },
        {
            id: '13',
            img: 'images/projects/RS16_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-13-StuyvesantHeights'
        },
        {
            id: '14',
            img: 'images/projects/RS17_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-14-StuyvesantHeights'
        },
        {
            id: '15',
            img: 'images/projects/RS18_front_card_sold.png',
            tag: 'realestate',
            title: 'Brownstone Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-15-Brownstone'
        },
        {
            id: '16',
            img: 'images/projects/RS19_exterior_card_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-16-CrownHeghts'
        },
        {
            id: '17',
            img: 'images/projects/RS22_front_card_sold.png',
            tag: 'realestate',
            title: 'Ditmas Park Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-17-DitmasPark'
        },
        {
            id: '18',
            img: 'images/projects/RS23_exterior_card_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-18-BedfordStuyvesant'
        },
        {
            id: '19',
            img: 'images/projects/RS20_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
        },
        {
            id: '20',
            img: 'images/projects/RS21_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
        },
        {
            id: '66',
            img: 'images/projects/Construction_Buildingmain.jpg',
            tag: 'construction',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-reno1'
        },
        {
            id: '77',
            img: 'images/projects/Construction_Housemain.jpg',
            tag: 'construction',
            title: 'Great Neck House',
            city: 'NEW YORK',
            route: '/project-details-reno2'
        },
        {
            id: '88',
            img: 'images/team/3.png',
            tag: 'construction',
            title: 'Wonderful Bridge',
            city: 'NEW YORK',
        },
        {
            id: '99',
            img: 'images/projects/Construction_Pharmacymain.jpg',
            tag: 'construction',
            title: 'Pharmacy',
            city: 'NEW YORK',
            route: '/project-details-pharm'
        },
        {
            id: '100',
            img: 'images/team/5.png',
            tag: 'construction',
            title: 'Modern Architecture',
            city: 'NEW YORK',
        },

    ];

    const [filterKey, setFilterKey] = useState('*')

    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.element-item',
            percentPosition: true,
            // layoutMode: 'packery',
            // masonry: {
            //     columnWidth: '.grid-sizer'
            // },
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            // columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy()
    }, []);

    // handling filter key change
    useEffect(() => {
        filterKey === '*'
            ? Isotope.current.arrange({ filter: `*` })
            : Isotope.current.arrange({ filter: `.${filterKey}` })
    }, [filterKey]);

    const handleFilterKeyChange = key => () => setFilterKey(key);
    return (
        <div class="main_wrapper">
            <div class="project_iso project_iso1 pd_tp_110 pb-0">
                <div class="container-fluid g-0">
                    <div class="row g-0">
                        <div class="col">
                            <div class="button-group filters-button-group">
                                <button className="button" onClick={handleFilterKeyChange('*')}>All <sup className="filter-count"></sup></button>
                                <button className="button" onClick={handleFilterKeyChange('realestate')}>Real Estate <sup className="filter-count"></sup></button>
                                <button className="button" onClick={handleFilterKeyChange('construction')}>Construction <sup className="filter-count"></sup></button>
                                <button className="button" onClick={handleFilterKeyChange('business')}>Business <sup className="filter-count"></sup></button>
                            </div>

                            <div class="grid grid-5">

                                {
                                    ProjectData02.map(data => <ProjectCard02
                                        key={data.id}
                                        data={data}
                                    />)
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Banner
                title='Take a Look at What We’ve Built and Sold! From Dreams to Reality, One Project at a Time'
                heading='Call Us Anytime'
                phone='1-844-444-4097'
                bannerType='banner banner_bg_color'
            />
        </div>
    );
};

export default ProjectsContainer02;