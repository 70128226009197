import $ from 'jquery';
import React, { useEffect } from 'react';

const ProjectDetailsContainerReno2 = () => {
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <img src="images/projects/Renovation_Housemain.jpg" alt="blog" />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">House Renovation: A Fusion of Elegance and Practicality</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Welcome to our exquisitely renovated home, a space that has been reimagined to provide a seamless blend of classic architecture and modern design. As you step into this abode, you'll be greeted by interiors that echo a heritage of craftsmanship while offering the amenities of contemporary living.</p>
                                        <p>Our renovation philosophy embraced the character of the original structure, infusing it with a modern sensibility. The result is an open-plan layout that feels spacious and bright, with each room flowing into the next. The use of natural materials like wood and stone adds warmth and texture, creating inviting spaces that are both beautiful and functional.</p>
                                        <p>Ample lighting, both natural and thoughtfully placed fixtures, illuminates the home's best features, casting a glow that's both uplifting and calming. It's a testament to our dedication to creating spaces where life's moments can be savored in a setting that's both tranquil and refined.</p>
                                        <h4 class="widget_title">
                                        Sleek and Functional Design
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Service</p>
                                        <ul class="point_order">
                                            <li>Tailored Spaces for Personalized Service: The layout of the home is intuitive, offering dedicated areas for relaxation, entertainment, and personal pursuits. Each room is a canvas for your lifestyle, designed to adapt and respond to the way you live.</li>
                                            <li>Counseling Area: Private nooks and cozy corners provide the perfect setting for quiet reflection or intimate conversations. Whether its a window seat nestled under a bay window or a secluded spot in the garden, these areas invite you to pause and recharge.</li>
                                            <li>Product Displays: Open shelving and display areas allow you to showcase your personal treasures and keepsakes. Designed to blend seamlessly with the home's architecture, they provide a platform for your style to shine through.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Renovation_Housep1.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Renovation_Housep2.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                        Embarking on this house renovation, we were inspired to create a living space that resonates with both the charm of yesteryears and the convenience of modern-day amenities. Our commitment was to ensure that each space within the home was both functional and aesthetically pleasing.
                                        </p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The reimagined floor plan offers an open, flowing layout that encourages natural light to cascade through spacious rooms. Functionality meets elegance with polished wooden floors that lead to expansive living areas, perfect for family gatherings or a serene retreat.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Utilizing cutting-edge construction technology, we've ensured the structural integrity of the house while enhancing its energy efficiency. From the insulated walls that keep the warmth in to the smart home systems that offer control and convenience, every aspect of the house is built to last.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Design Philosophy</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Our design philosophy was anchored in creating harmony between the home's original features and modern enhancements. The thoughtful restoration of vintage details alongside the introduction of sleek finishes and contemporary materials exemplifies this balanced approach.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Modern Amenities for Today's Lifestyle</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Today's homeowners expect the best of both worlds: historic charm and modern convenience. This renovation delivers just that with a state-of-the-art kitchen, spa-like bathrooms, and integrated smart home technology for an effortlessly luxurious living experience.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                        As the renovation comes to completion, the house stands as a testament to what can be achieved when tradition meets innovation. It's a place where memories of the past can be cherished and new stories can begin. We invite you to step inside and see how a thoughtful renovation can provide the perfect setting for your lifestyle.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>The transformation of our home has been nothing short of miraculous. Walking through the doors now feels like entering a realm where every detail has been crafted to perfection. The balance of the original structure's charm with modern design elements has given our space a soulful yet contemporary edge. The team's ability to bring out the character of each room while infusing it with modern luxury has made our home not just a living space, but a piece of art. The natural flow and intuitive design make every day a delight, and the thoughtful integration of state-of-the-art features has elevated our lifestyle. We are truly grateful to dwell in such a beautifully reimagined space that reflects our personal style while honoring the home's heritage. A heartfelt thank you to the entire renovation team for turning our house into a home we're proud to showcase.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>House</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Two Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerReno2;