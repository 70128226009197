import $ from 'jquery';
import React, { useEffect } from 'react';

const ProjectDetailsContainerPharm = () => {
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <img src="images/projects/Pharmacy_main.jpg" alt="blog" />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Pharmacy Renovation</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Welcome to our newly renovated pharmacy – a contemporary space designed with your health and convenience in mind. From the moment you step through our doors, you'll notice a transformation that merges cutting-edge design with the comfortable feel of your neighborhood pharmacy.</p>

                                        <p>
                                        Our state-of-the-art interior features clean lines and a modern aesthetic. The open-plan layout ensures easy navigation through our wide range of health and wellness products, while the ample lighting highlights our commitment to a transparent and welcoming environment.
                                        </p>

                                        <h4 class="widget_title">
                                        Sleek and Functional Design
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Service</p>
                                        <ul class="point_order">
                                            <li>Drop-Off and Pick-Up Zones: We've streamlined the process of dropping off and picking up prescriptions. Clear signage leads you to designated areas, ensuring a swift and efficient service.</li>
                                            <li>Counseling Area: For privacy and comfort, our dedicated counseling area provides a discreet space for consultations with our pharmacists, who are always ready to offer expert advice and support.</li>
                                            <li>Product Displays: Our products are organized intuitively across the pharmacy, with dedicated sections for skincare, vitamins, and more. The uncluttered shelving makes it easy to find what you need.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Pharmacy_p1.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Pharmacy_p2.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                        We've taken every step to ensure our pharmacy renovation meets the highest standards of service and convenience. From the initial design to the final implementation, our goal has been to create a space that is both inviting and efficient.
                                        </p>
                                        <p>In our pursuit of excellence, we've adopted a phased approach to our renovation services. Each step is crafted to enhance customer experience and streamline pharmacy operations.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Our new floor plan optimizes workflow and customer traffic. With wider aisles for easy navigation, clearly marked zones for prescription drop-off and pick-up, and private consultation areas, our layout is designed with your comfort and privacy in mind.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Incorporating the latest in construction technology, we've utilized advanced materials and building techniques to improve the durability and hygiene of our pharmacy. From antimicrobial surfaces to HEPA-filtered air systems, every detail contributes to a healthier environment.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The driving philosophy behind our renovation was to build a pharmacy that embodies trust and care. We considered the community’s needs, the evolving landscape of healthcare, and the importance of accessibility to shape a space that stands at the forefront of modern pharmacy design.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Innovative Solutions for Enhanced Pharmacy Operations</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Embracing the future of pharmaceutical service, our renovation introduced cutting-edge solutions designed to elevate both customer experience and pharmacy workflow. Smart shelving systems now provide real-time inventory tracking to ensure we always have your prescriptions in stock. Automated dispensing technology guarantees accuracy and speed in prescription fulfillment, allowing our pharmacists more time for patient care. Our investment in these technologies ensures that our pharmacy is not just a place to fill prescriptions, but a cornerstone of health in our community.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                        Post-renovation, our pharmacy stands as a beacon of innovation in healthcare retail. We've not only modernized the space but also integrated smart solutions to maintain hygiene and ensure the well-being of our patrons.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>From the moment I walked into the newly renovated space, I was impressed by the careful attention to detail and the modern, yet welcoming atmosphere. The pharmacy team has always been wonderful, but the new layout with clear signage for drop-off and pick-up has made my visits even more efficient. The private consultation area is a testament to their dedication to customer privacy and care. It's clear that the pharmacy has invested in not only state-of-the-art facilities but also in creating a space where customers can feel comfortable and taken care of. A fantastic upgrade to our community!</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Pharmacy</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Two Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerPharm;