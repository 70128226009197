import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerReno1 from '../../Containers/ProjectContainers/ProjectDetailsContainerReno1';

const ProjectDetailsReno1 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Building Renovation' />

            <ProjectDetailsContainerReno1 />

        </>
    );
};

export default ProjectDetailsReno1;