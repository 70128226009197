import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerCommercial from '../../Containers/ServiceContainers/ServiceContainerCommercial';

const ServiceDetailsCommercial = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Comertial Construction' heading='Comertial Construction' />
            <ServiceContainerCommercial />
        </>
    );
};

export default ServiceDetailsCommercial;
